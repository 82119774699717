import React, {useEffect, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {Alert, Box, Button, FormGroup, MenuItem, Snackbar, Typography} from "@mui/material";
import {Field, Form, Formik} from "formik";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {number, object, string} from "yup";
import {instanceToInstance} from "class-transformer";
import SpecialistModel from "../../models/scheiber.hu/SpecialistModel";
import appointmentService from "../../services/scheiber.hu/AppointmentService";
import AppointmentModel from "../../models/scheiber.hu/AppointmentModel";
import {GVSelect, GVTextField} from "gecsevar-formik-fields";
import {SchoolClasses} from "../../models/scheiber.hu/SchoolClasses";

const recaptchaRef = React.createRef<ReCAPTCHA>()

const formValidationSchema = object({
    appointmentModel: object({
        teacherId: number().moreThan(0, "Válasszon tanárt!"),
        senderName: string().required('Kötelező mező!'),
        senderContactEmail: string().email('Nem megfelelő e-mail formátum').required('Kötelező mező!'),
        senderContactMobile: string().required('Kötelező mező!'),
        senderRelative: string().required('Kötelező mező!'),
        senderRelativeClass: string().required('Kötelező mező!'),
        timeValue: string().required('Kötelező mező!'),
    })
});
type FreeTime = {
    timeValue: string
}

export function Fogadoest() {

    const [loaded, setLoaded] = useState(false)
    const [specialists, setSpecialists] = useState<Array<SpecialistModel>>(new Array<SpecialistModel>())
    const [timeList, setTimeList] = useState<Array<FreeTime>>(new Array<FreeTime>())
    const [errorMsg, setErrorMsg] = useState("")
    const [open, setOpen] = React.useState(false);
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorMsg("")
        setOpen(false);
    }
    const updateTimeList = (teacherName: string) => {
        appointmentService.getTimeList(teacherName, (resp) => {
            const temp: Array<{ timeValue: string }> = resp.data.map((value: string) => {
                return instanceToInstance(value)
            })
            if (temp.length === 0) {
                setErrorMsg("Nincs már szabad időpont!")
                setOpen(true);
            }
            setTimeList(temp)
        })
    }
    useEffect(() => {
        setLoaded(false)    // Force rerender page
        appointmentService.getSpecialists((resp) => {
            const temp: Array<SpecialistModel> = resp.data.map((value: string) => {
                return instanceToInstance(value)
            })
            setSpecialists(temp)
            setLoaded(true)
        })
    }, [])

    if (!loaded) {
        return (<Box>
                Page loading...
            </Box>
        )
    } else {
        return <Box sx={{display: 'grid', justifyContent: 'center', p: 2}} width={"100%"}>
            <Typography variant={"h5"} fontStyle={"normal"} margin={0} align={"center"} width={"100%"} marginBottom={2}>
                Fogadóest - Időpontfoglalás | November 20, Hétfő
            </Typography>
            <Formik
                initialValues={{
                    appointmentModel: new AppointmentModel(),
                    specialList: specialists,
//                        freeTimeList: timeList
                }}
                onSubmit={(values, actions) => {
                    actions.setSubmitting(true)
                    appointmentService.postAppointment(JSON.stringify(values.appointmentModel), (status, resp) => {
                        if (status === 200) {
                            setErrorMsg("")
                            setOpen(true)
                            actions.resetForm()
                            recaptchaRef.current?.reset()
                        } else {
                            if (typeof resp === "string") {
                                if (resp === "ERROR_APPOINTMENT_TIME") {
                                    updateTimeList(values.appointmentModel.teacherName)
                                    setErrorMsg("Az időpont már le van foglalva! Kérem válasszon másikat!")
                                    setOpen(true)
                                } else {
                                    setErrorMsg(resp)
                                    setOpen(true)
                                }
                            }
                            recaptchaRef.current?.reset()
                        }
                        actions.setSubmitting(false);
                    })
                }}
                validationSchema={formValidationSchema}
                validateOnChange={true}    // if true, gets fucking slow ;)
                validateOnBlur={true}
                enableReinitialize={false}
            >
                {({values, handleChange, errors, isSubmitting}) => (
                    <Form>
                        <Box>
<p>Tisztelt Sz&uuml;lők!</p>
<p style={{textAlign: 'justify'}}>&Eacute;ves esem&eacute;nynapt&aacute;runk szerint <strong>2023. november 20-&aacute;n</strong> fogad&oacute;est&uuml;nk lesz az<strong> 1-12. &eacute;vfolyamon tanul&oacute;k sz&uuml;lei sz&aacute;m&aacute;ra</strong>, amit jelenl&eacute;ti form&aacute;ban tartunk meg az iskol&aacute;ban. Az al&aacute;bbi regisztr&aacute;ci&oacute;s fel&uuml;leten tudnak bejelentkezni koll&eacute;g&aacute;inkhoz, amit sz&iacute;veskedjenek legk&eacute;sőbb <strong>2023. november 19. (vas&aacute;rnap) 12.00 &oacute;r&aacute;ig megtenni.</strong></p>
<p style={{textAlign: 'justify'}}><em>A megszokott negyed&eacute;ves &eacute;rt&eacute;kel&eacute;seket az oszt&aacute;lyfőn&ouml;k&ouml;k 2023. november 17. p&eacute;neteken osztj&aacute;k meg &Ouml;n&ouml;kkel, &iacute;gy k&ouml;nny&iacute;tve meg a d&ouml;nt&eacute;st, hogy melyik tan&aacute;rhoz sz&uuml;ks&eacute;ges kiemelten regisztr&aacute;lniuk.</em></p>
<p style={{textAlign: 'justify'}}><strong>Pedag&oacute;gusainkkal, az iskol&aacute;ban 2023. november 20. h&eacute;tfőn az előzetes regisztr&aacute;ci&oacute; alapj&aacute;n tudnak konzult&aacute;lni.</strong><br /><strong>A konzult&aacute;ci&oacute;k helysz&iacute;neit az iskola bej&aacute;rat&aacute;n&aacute;l &eacute;s minden emeleten megtal&aacute;lj&aacute;k majd.</strong></p>
<p><em>Regisztr&aacute;ci&oacute; n&eacute;lk&uuml;l is v&aacute;rjuk &Ouml;n&ouml;ket, de a regisztr&aacute;ci&oacute;val rendelkezők elsőbbs&eacute;get &eacute;lveznek!</em></p>
<br/>
                            <p><strong>Regisztr&aacute;ci&oacute;hoz k&eacute;rj&uuml;k t&ouml;ltse ki az al&aacute;bbi űrlapot:</strong></p>
                            <FormGroup>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: "top", horizontal: "center"}}>
                                        <Alert onClose={handleClose} severity={errorMsg.length === 0 ? "success" : "error"} sx={{width: '100%'}}>
                                            {errorMsg.length === 0 ? "Sikeres küldés!" : errorMsg}
                                        </Alert>
                                    </Snackbar>
                                    <Box>
                                        <Field key={1} name={`appointmentModel.teacherName`} label={"Tanár neve"} component={GVSelect}
                                               onChange={(e: React.ChangeEvent<any>) => {
                                                   updateTimeList(e.target.value)
                                                   handleChange(e)
                                               }}
                                        >
                                            {values.specialList &&
                                                values.specialList.map((item) => (
                                                    <MenuItem key={item.tanar_nev} value={item.tanar_nev}>{item.tanar_nev} ({item.munkakor})</MenuItem>
                                                ))
                                            }
                                        </Field>
                                    </Box>
                                    <Box sx={{display: {sm: 'block', md: 'flex'}}} marginTop={1}>
                                        <Field key={2} name={`appointmentModel.senderName`} label={"Szülő/Gondviselő neve"} component={GVTextField}/>
                                        <Field key={3} name={`appointmentModel.senderContactEmail`} label={"Szülő/Gondviselő e-mail"} component={GVTextField}/>
                                        <Field key={4} name={`appointmentModel.senderContactMobile`} label={"Szülő/Gondviselő telefon"} component={GVTextField}/>
                                    </Box>
                                    <Box sx={{display: 'flex'}} marginTop={1}>
                                        <Field key={5} name={`appointmentModel.senderRelative`} label={"Tanuló neve"} component={GVTextField}/>
                                        <Field key={6} name={`appointmentModel.senderRelativeClass`} label={"Tanuló osztálya"} component={GVSelect}>
                                            {SchoolClasses.map((key,index, _) => {
                                                return <MenuItem key={index} value={key.value}>{key.value}</MenuItem>
                                            })
                                            }
                                        </Field>
                                    </Box>
                                    <Box sx={{display: 'flex'}} marginTop={1}>
                                        <Field key={7} name={`appointmentModel.date`} label={"Fogadóest napja"} component={GVTextField} disabled={true}/>
                                        <Field key={8} name={`appointmentModel.timeValue`} label={"Időpont"} component={GVSelect}>
                                            {timeList &&
                                                timeList.map((item: { timeValue: string }, index) => (
                                                    <MenuItem key={index} value={item.timeValue}>{item.timeValue}</MenuItem>
                                                ))
                                            }
                                        </Field>
                                    </Box>
                                    <Box marginTop={2} marginLeft={1}>
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            size="normal"
                                            sitekey="6Le5i_MoAAAAANHXJjjbMdEev2rZjlCYLlGPNHdU"
                                            onChange={(token) => {
                                                if (token != null) {
                                                    values.appointmentModel.recaptchaToken = token
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{display: 'flex', justifyContent: 'center'}} margin={4}>
                                        <Button color={"primary"} variant={"contained"} type={"submit"}>Foglalás</Button>
                                    </Box>
                                </LocalizationProvider>
                            </FormGroup>
                        </Box>
                    </Form>
                    )}
            </Formik>
        </Box>
    }
    }