import {DrawerMenuModel} from "../models/DrawerMenuModel";

export type HeaderButton = {
    title: string,
    route: string,
    element: string;
}
export enum HTML_HEAD_META {
    META_TITLE,
    META_DESCRIPTION,
    META_NAME,
}

export interface IGecsevarConfig {
    initialize(initCallback: () => void): void
    getHtmlHead(meta: HTML_HEAD_META): string
    getHeaderLogo(size: string): string
    getHeaderButtons(): HeaderButton[]
    getHeaderButtonElementBy(name: string): JSX.Element
    getMenuTitle(): string
    getMenuBackground(): string
    getContainerBackground(): string
    getContainerInnerGridBackground(): string
    getContainerInnerPaperBackground(): string
    getArticleListPageBackground(): string
    getMenuNavigation(): Array<DrawerMenuModel>
    getSubMenuFor(menuId: string): Array<DrawerMenuModel>
    getDefaultArticleListCategory(): string
    getDashboard(): JSX.Element
    getFooterContent(): JSX.Element
    getCopyrightContent(): JSX.Element
    getItemsCountOnArticleListPage(): number
}