import {alpha, Card, CardActionArea, CardContent, CardMedia, Grid, Paper, Typography} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import {gecsevarTheme} from "../../gecsevar_framework/pages/MainPage";

type ClickableCardProps = {
    title: string
    description: string
    image: string
    link: () => void
}

function ClickableCard(props: ClickableCardProps) {

    return <Card sx={{ margin: "10px", backgroundColor:'inherit', color:'inherit',
            justifyContent:{sm:'normal', md:'space-between'}
    }}>
        <CardActionArea sx={{display: "flex", justifyContent: 'space-between'}} onClick={props.link}>
            <CardMedia
                sx={{height: 70, width: 70}}
                image={props.image}
                title={props.title}
            />
            <CardContent sx={{flex: '1 2 auto', display: {xs: 'none', sm:'none', md:'block'}}}>
                <Typography gutterBottom variant="h5" component="div">
                    {props.title}
                </Typography>
                <Typography variant="body2">
                    {props.description}
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card>
}

export enum MyBoxPositions  {
    none,
    top,
    side,
}

type FontosHivatkozasokProps = {
    position: MyBoxPositions
}

export function FontosHivatkozasok(props: FontosHivatkozasokProps) {

    const navigate = useNavigate()

    if (props.position === MyBoxPositions.top) {
        return <Paper sx={{color: 'white', bgcolor: 'primary.main', display:"flex", justifyContent: 'space-evenly'}}>
            <ClickableCard title={"eKréta"} description={"KRÉTA Iskolai Alaprendszer"}
                           image={"/images/banner/kreta.jpg"} link={() => {window.open("https://scheiber.e-kreta.hu/")}}/>
            <ClickableCard title={"Alapítvány"} description={"Alkotó Ifjúság Alapítvány"}
                           image={"/images/banner/aia_4ece.jpg"} link={() => {navigate('/article_list/palyazat')}}/>
            <ClickableCard title={"eMenza"} description={"Online étkezés nyilvántartás"}
                           image={"/images/banner/menza.jpg"} link={() => {window.open("https://scheiber.e-menza.hu/")}}/>
        </Paper>
    } else if (props.position === MyBoxPositions.side) {
        return <Paper sx={{display: "block", direction:"column", justifyContent: 'stretch', width:'maxWidth', backgroundColor:'primary.main', color:'white'}}>
            <ClickableCard title={"eKréta"} description={"KRÉTA Iskolai Alaprendszer"}
                           image={"/images/banner/kreta.jpg"} link={() => {window.open("https://scheiber.e-kreta.hu/")}}/>
            <ClickableCard title={"Alapítvány"} description={"Alkotó Ifjúság Alapítvány"}
                           image={"/images/banner/aia_4ece.jpg"} link={() => {navigate('/article_list/palyazat')}}/>
            <ClickableCard title={"eMenza"} description={"Online étkezés nyilvántartás"}
                           image={"/images/banner/menza.jpg"} link={() => {window.open("https://scheiber.e-menza.hu/")}}/>
        </Paper>
    } else {
        return <></>
    }
}