import React, {useEffect} from "react";
import {DateCalendar, DayCalendarSkeleton, LocalizationProvider, PickersDay, PickersDayProps} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import dayjs, {Dayjs} from "dayjs";
import {instanceToInstance} from "class-transformer";
import {Badge, Divider, List, ListItem, ListItemText, Paper, Tooltip, Typography} from "@mui/material";
import eventCalenderService from "../../services/scheiber.hu/EventCalenderService";
import {MyBoxPositions} from "./FontosHivatkozasok";

moment.locale('hu'); // default the locale to English
var fr = moment().locale('fr');
fr.locale('en');

moment.updateLocale("hu", {
    week: {
        dow: 1
    },
    months: 'január_február_március_április_május_június_július_augusztus_szeptember_október_november_december'.split('_'),
    monthsShort: 'jan._feb._már_ápr._máj_jún_júl._aug_sze._okt._nov._dec.'.split('_'),
    monthsParseExact: true,
    weekdays: 'vasárnap_hétfő_kedd_szerda_csütörtök_péntek_szombat'.split('_'),
    weekdaysShort: 'vas._hét._ked._sze._csü._pén._szo.'.split('_'),
    weekdaysParseExact: true,
})

type EventDayProp = {
    day: number
    event: string
}

function EventDays(props: PickersDayProps<Dayjs> & { highlightedDays?: Array<EventDayProp> }) {

    const {highlightedDays = [], day, outsideCurrentMonth, ...other} = props;
    const isSelected = !props.outsideCurrentMonth &&
        (
            highlightedDays.findIndex((item) => {
                return item.day === day.date()
            }) !== -1
        )
    const events = highlightedDays.find((item) => {
        return item.day === day.date()
    })
    const eventCounter = (events !== undefined ? events.event.split('|').length : 1)
    return (
        <Tooltip title={""}>
        <Badge
            key={'badge_' + props.day.toString()}
            overlap="circular"
            color={"secondary"}
            badgeContent={isSelected ? eventCounter : undefined}
            sx={{marginTop: 1}}
        >
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day}/>
        </Badge>
        </Tooltip>
    );
}

type EsemenyNaptarProps = {
    position: MyBoxPositions
}
// Isreal flag '🇮🇱'
export function EsemenyNaptar(props: EsemenyNaptarProps) {

    const [highlightedDays, setHighlightedDays] = React.useState(new Array<EventDayProp>())
    const [isLoading, setIsLoading] = React.useState(false);

    const handleMonthChange = (date: Dayjs) => {

        eventCalenderService.getEvents(date.year(), date.month() + 1, (status, resp) => {
            if (status === 200) {
                const temp: Array<EventDayProp> = resp.data.map((value: string) => {
                    return instanceToInstance(value)
                })
                setHighlightedDays(temp)
                setIsLoading(false);
            }
        })
    }
    useEffect(() => {
        handleMonthChange(dayjs())
    }, [])

    return <Paper elevation={2} sx={{
        p: 1,
        m: '8px',
        position: 'center',
        //height: `calc(100% - 16px)`,
        backgroundColor: 'rgba(255,255,255,0.7)'
    }}>
        <Typography variant={"h5"} fontWeight={"bold"}>Eseménynaptár</Typography>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"hu"}>
            <DateCalendar
                displayWeekNumber
                loading={isLoading}
                onMonthChange={handleMonthChange}
                renderLoading={() => <DayCalendarSkeleton/>}
                slots={{
                    day: EventDays
                }}
                slotProps={{
                    previousIconButton: {color: "primary"},
                    nextIconButton: {color: "primary"},
                    day: {
                        highlightedDays,
                    } as any
                }}
                sx={{width: 'inherit'}}
            />
        </LocalizationProvider>
        {props.position === MyBoxPositions.top ?
        <List key={'Calendar'} sx={{width: '100%'}}>
            <ListItem key={-1}>
                <ListItemText><Typography variant={"body1"} fontWeight={"bold"}>NAP</Typography></ListItemText>
                <ListItemText><Typography variant={"body1"} fontWeight={"bold"}>Esemény</Typography></ListItemText>
            </ListItem>
            {highlightedDays.map((value, index) => (
                <div key={index}>
                    <ListItem alignItems={"flex-start"}>
                        <ListItemText
                            primaryTypographyProps={{
                                paddingRight: 2,
                                fontWeight: 'bold',
                                fontStyle: 'italic',
                                color:'primary.main',
                            }}
                            style={{maxWidth: "fit-content", wordBreak: "normal",}}
                            primary={`${value.day}`}
                        />
                        <ListItemText>
                            <Typography variant={"body2"} textAlign={"end"} sx={{color:'primary.main', wordWrap: 'break-word'}}>{value.event}</Typography>
                        </ListItemText>
                    </ListItem>
                    <Divider variant="inset" component="li"/>
                </div>
            ))}
        </List>
            :
            <></>
        }
    </Paper>
}