import {DrawerMenuModel} from "../../models/DrawerMenuModel";
import menuService from "../../services/MenuService";
import {AxiosResponse} from "axios";
import {instanceToInstance} from "class-transformer";
import {Dashboard} from "../../../pages/madregot.hu/Dashboard";
import {Footer} from "../../../pages/madregot.hu/Footer";
import {Copyright} from "../../../pages/madregot.hu/Copyright";
import {HeaderButton, HTML_HEAD_META, IGecsevarConfig} from "../IGecsevarConfig";
import {FontosHivatkozasok, MyBoxPositions} from "../../../pages/scheiber.hu/FontosHivatkozasok";

export class MadregotConfig implements IGecsevarConfig {

    private initialized = false
    private menuMap = new Array<DrawerMenuModel>()
    private headerButtons = new Array<HeaderButton>()

    constructor() {

    }

    initialize(initCallback: () => void) {
        menuService.get((resp: AxiosResponse) => {
            this.menuMap = instanceToInstance(resp.data)
            this.initialized = true
            initCallback()
        })
    }

    getHtmlHead(meta: HTML_HEAD_META): string {
        let res = ""

        switch (meta) {
            case HTML_HEAD_META.META_TITLE:         {
                res = "BZSH Scheiber Sándor Gimnázium és Általános Iskola"
                break
            }
            case HTML_HEAD_META.META_DESCRIPTION:   {
                res = "Budapesti Zsidó Hitközösség, Scheiber Sándor Gimnázium és Általános Iskola hivatalos oldala"
                break
            }
            case HTML_HEAD_META.META_NAME:          {
                res = "name"
                break
            }
        }
        return res
    }

    getHeaderLogo(size: string): string {
        if (size === "xs") {
            return "/images/scheiberlogo2.png"
        } else if (size === "sm") {
            return "/images/scheiberlogo1.png"
        }
        return ""
    }

    getHeaderButtons(): HeaderButton[] {
        if (!this.initialized)
            console.error("Gecsevar Config Uninitialized!")
        return this.headerButtons
    }

    getHeaderButtonElementBy(name: string): JSX.Element {
        if (!this.initialized)
            console.error("Gecsevar Config Uninitialized!")
        switch (name) {
            case 'Uzenetkuldes' :
                return <div/>
            case 'Fogadoest'    :
                return <div/>
        }
        console.error('Missing Element for: ' + name)
        return <></>
    }

    getMenuTitle(): string {
        return "Tartalom"
    }

    getMenuBackground(): string {
        return 'linear-gradient(to top, #002147aa, #002147dd), url() no-repeat top center'
    }

    getContainerBackground(): string {
        return ''   // default
    }

    getContainerInnerGridBackground(): string {
        return ''   // default
    }

    getContainerInnerPaperBackground(): string{
        return ''   // default
    }

    getArticleListPageBackground(): string {
        return 'primary.dark'
    }

    getMenuNavigation(): Array<DrawerMenuModel> {
        if (!this.initialized)
            console.error("Gecsevar Config Uninitialized!")
        return this.menuMap.filter(value => {
            return value.level !== "submenu"
        })
    }
    getSubMenuFor(menuId: string): Array<DrawerMenuModel> {
        if (!this.initialized)
            console.error("Gecsevar Config Uninitialized!")
        let inZone = false;
        const temp: Array<DrawerMenuModel> = [];
        this.menuMap.map(value => {
            if (value.id === menuId) {
                inZone = true
            } else if (inZone && value.level === "submenu") {
                temp.push(value)
            } else if (inZone && value.level !== "submenu") {
                inZone = false
            }
            return null
        })

        return temp
    }

    getDefaultArticleListCategory(): string {
        return "article"
    }

    getDashboard(): JSX.Element {
        return <Dashboard/>
    }
    getFooterContent(): JSX.Element {
        return <Footer/>
    }
    getCopyrightContent(): JSX.Element {
        return <Copyright/>
    }
    getItemsCountOnArticleListPage(): number {
        return 5
    }
}
