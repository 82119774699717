import {Grid} from "@mui/material";
import React from "react";
import {gecsevarConfig} from "../../gecsevar_framework/pages/MainPage";
import {ArticleListPage, ArticleListPageType} from "../../gecsevar_framework/components/ArticleListPage";

export function Dashboard() {

    return (
        // INFO margin avoids overlap footer, check it out what goes wrong
        <Grid container margin={0} spacing={0} direction={"row"} justifyContent={"center"} alignItems={"stretch"}>
            <Grid item xs={12} md={6} lg={4} >
                <ArticleListPage type={ArticleListPageType.dashboard} category={gecsevarConfig.getDefaultArticleListCategory()} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} >

            </Grid>
            <Grid item xs={12} md={6} lg={4} >

            </Grid>
            <Grid item xs={12} md={12} lg={12} margin={0}>

            </Grid>
            <Grid item xs={12} md={12} lg={12} margin={2}>

            </Grid>
        </Grid>
    )
}
