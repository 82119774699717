import {Box, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import React from "react";
import {AccountBalance, Call, Computer, Facebook, FmdGood, Group, Instagram, Mail, Restaurant, Subtitles, YouTube} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

export function Footer() {

    const navigate = useNavigate()
//             color: 'white',
//             background: 'linear-gradient(to top, #002147bb, #002147fa), url(/images/background/zuglo_cover.jpg) no-repeat top center',
// display: "flex", justifyContent: 'space-evenly'
    return (
        <Box sx={{
            width: '100%',
            color: 'white',
            backgroundColor: 'primary.main',
        }}>
            <Grid marginTop={0} container direction="column" justifyContent="center" alignItems="center">
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={4} md={4}>
                            <Grid container component={"span"} direction="column" justifyContent="center" alignItems="center"
                                  marginTop={4} marginBottom={4}>
                                <Grid item>
                                    <Box component={"img"} sx={{height: "auto", maxHeight: "50px", maxWidth: "100%",}}
                                         alt="LOGO"
                                         src="/images/scheiberlogo1.png"
                                    />
                                    <Typography variant={"body1"} fontStyle={"italic"} marginTop={2} marginBottom={2}>
                                        Kövessen minket:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton color={"secondary"} onClick={() => window.open("https://www.facebook.com/scheiberiskola")}><Facebook
                                        htmlColor={"white"} fontSize={"large"}/></IconButton>
                                    <IconButton color={"secondary"} onClick={() => window.open("https://www.instagram.com/scheibersuli")}><Instagram
                                        htmlColor={"white"} fontSize={"large"}/></IconButton>
                                    <IconButton color={"secondary"}
                                                onClick={() => window.open("https://www.youtube.com/channel/UC8ARS2CuUVG1mrua7Vo9vow")}><YouTube
                                        htmlColor={"white"} fontSize={"large"}/></IconButton>
                                    <IconButton color={"secondary"} onClick={() => window.open("https://scheiber.e-menza.hu/")}><Restaurant htmlColor={"white"}
                                                                                                                                            fontSize={"large"}/></IconButton>
                                    <IconButton color={"secondary"} onClick={() => window.open("https://scheiber.e-kreta.hu/")}><Computer htmlColor={"white"}
                                                                                                                                          fontSize={"large"}/></IconButton>
                                    <IconButton color={"secondary"} onClick={() => window.open("https://webmail.scheiber.hu/")}><Mail htmlColor={"white"}
                                                                                                                                      fontSize={"large"}/></IconButton>
                                    <IconButton color={"secondary"} onClick={() => window.open("https://basecamp.com/")}><Group htmlColor={"white"}
                                                                                                                                fontSize={"large"}/></IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography variant={"h5"} color={"white"} marginTop={8} marginBottom={0} align={"center"} fontWeight={"bold"}>
                                Alkotó Ifjúság Alapítvány
                            </Typography>
                            <List>
                                <ListItem key={0} alignItems={"flex-start"}>
                                    <ListItemIcon><FmdGood color={"secondary"}/></ListItemIcon>
                                    <ListItemText>1145 Budapest, Laky Adolf u. 38-40.</ListItemText>
                                </ListItem>
                                <ListItem key={1} alignItems={"flex-start"}>
                                    <ListItemIcon><Call color={"secondary"}/></ListItemIcon>
                                    <ListItemText>+361 469 02 77</ListItemText>
                                </ListItem>
                                <ListItem key={2} alignItems={"flex-start"}>
                                    <ListItemIcon><Mail color={"secondary"}/></ListItemIcon>
                                    <ListItemText>scheiber@scheiber.hu</ListItemText>
                                </ListItem>
                                <ListItem key={3} alignItems={"flex-start"}>
                                    <ListItemIcon><AccountBalance color={"secondary"}/></ListItemIcon>
                                    <ListItemText>OTP Bank 11714006-20246510</ListItemText>
                                </ListItem>
                                <ListItem key={4} alignItems={"flex-start"}>
                                    <ListItemIcon><Subtitles color={"secondary"}/></ListItemIcon>
                                    <ListItemText>18004810-1-42</ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography variant={"h5"} color={"white"} marginTop={8} marginBottom={0} align={"center"}
                                        fontWeight={"bold"}
                            >
                                BZSH Scheiber Iskola
                            </Typography>
                            <List>
                                <ListItem key={0} alignItems={"flex-start"}>
                                    <ListItemIcon><FmdGood color={"secondary"}/></ListItemIcon>
                                    <ListItemText>1145 Budapest, Laky Adolf u. 38-40.</ListItemText>
                                </ListItem>
                                <ListItem key={1} alignItems={"flex-start"}>
                                    <ListItemIcon><Call color={"secondary"}/></ListItemIcon>
                                    <ListItemText>Titkárság: 06 1 469 0280</ListItemText>
                                </ListItem>
                                <ListItem key={2} alignItems={"flex-start"}>
                                    <ListItemIcon><Call color={"secondary"}/></ListItemIcon>
                                    <ListItemText>Pénztár: 06 1 469 0281</ListItemText>
                                </ListItem>
                                <ListItem key={3} alignItems={"flex-start"}>
                                    <ListItemIcon><Mail color={"secondary"}/></ListItemIcon>
                                    <ListItemText>scheiber@scheiber.hu</ListItemText>
                                </ListItem>
                                <ListItem key={5} alignItems={"flex-start"}>
                                    <ListItemIcon><AccountBalance color={"secondary"}/></ListItemIcon>
                                    <ListItemText>OTP Bank 11714006-20414319</ListItemText>
                                </ListItem>
                                <ListItem key={6} alignItems={"flex-start"}>
                                    <ListItemIcon><Subtitles color={"secondary"}/></ListItemIcon>
                                    <ListItemText>18178254-1-42</ListItemText>
                                </ListItem>
                                <ListItem key={7} alignItems={"flex-start"}>
                                    <ListItemIcon><Subtitles color={"secondary"}/></ListItemIcon>
                                    <ListItemText>OM 064887</ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
            </Grid>
        </Box>
    )
}