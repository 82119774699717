import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Box, Card, Grid, Pagination, Paper, Skeleton, Stack, Typography} from "@mui/material";
import articleListService from "../services/ArticleListService";
import ArticleModel from "../models/ArticleModel";
import {plainToInstance} from "class-transformer";
import {MiniNewsCard} from "./MiniNewsCard";
import {MaxiNewsCard} from "./MaxiNewsCard";
import {gecsevarConfig} from "../pages/MainPage";

export enum ArticleListPageType {
    dashboard,
    page
}

type ArticleListPageParams = {
    type: ArticleListPageType
    category: string
}

export function ArticleListPage(props: ArticleListPageParams) {

    const param = useParams()

    useEffect(() => {

    }, []);

    return (props.type === ArticleListPageType.dashboard) ?
        <ArticleListAsDashboard key={1} category={param["category"] !== undefined ? param["category"] : props.category}/>
        :
        <ArticleListAsPage key={1} category={param["category"] !== undefined ? param["category"] : props.category}/>
}

type ArticleListAsDashboardProps = {
    category: string
}

function ArticleListAsDashboard(props: ArticleListAsDashboardProps) {

    const [articles, setArticles] = React.useState(new Array<ArticleModel>())
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        articleListService.getArticlesAtPage(props.category, 1, gecsevarConfig.getItemsCountOnArticleListPage(), (status, resp) => {
            if (status === 200) {
                const temp: Array<ArticleModel> = resp.data.map((value: string) => {
                    return plainToInstance(ArticleModel, value)
                })
                setArticles(temp)
                setLoaded(true)
            }
        })
    }, [props.category]);
    return (!loaded || articles === undefined || articles.length === 0) ?
        <Stack spacing={0} margin={2}>
            <Skeleton variant={"rectangular"}>
                <Card/>
            </Skeleton>
        </Stack>
        :
        <Paper elevation={2} sx={{
            p: 1,
            m: '8px',
            position: 'center',
            height: `calc(100% - 16px)`,
            backgroundColor: gecsevarConfig.getArticleListPageBackground(),
        }}>
            <Typography variant={"h5"} fontWeight={"bold"}>{props.category}</Typography>
            <Box style={{display: 'block', justifyContent: 'space-evenly'}}>
                {articles.map((item, index) => (
                    <MiniNewsCard key={index} article={item}/>
                ))}
            </Box>
        </Paper>
}

type ArticleListAsPageProps = {
    category: string
}

function ArticleListAsPage(props: ArticleListAsPageProps) {

    const [pageCount, setPageCount] = useState(1)
    const [loaded, setLoaded] = useState(false)
    const [articles, setArticles] = React.useState(new Array<ArticleModel>())

    useEffect(() => {
        articleListService.getItemCount(props.category, (status, resp) => {
            if (status === 200) {
                const pages = Math.ceil(resp.data["count"] / gecsevarConfig.getItemsCountOnArticleListPage())
                setPageCount(pages)
                updateArticles(1)
                setLoaded(true)
            }
        })
    }, [props.category]);

    const updateArticles = (value: number) => {
        articleListService.getArticlesAtPage(props.category, value, gecsevarConfig.getItemsCountOnArticleListPage(), (status, resp) => {
            if (status === 200) {
                const temp: Array<ArticleModel> = resp.data.map((value: string) => {
                    return plainToInstance(ArticleModel, value)
                })
                setArticles(temp)
            }
        })
    }

    return loaded ?
        <Paper elevation={2} sx={{p: 1, m: 1, position: 'relative', overflow: 'auto', minHeight: 500}}>
            <Pagination count={pageCount} color={"secondary"} variant={"text"} shape={"rounded"}
                        onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                            updateArticles(value)
                        }}/>
            <Grid container margin={0} spacing={0} direction={"row"} justifyContent={"space-between"} alignItems={"stretch"}>
                {articles.map((item, index) => (
                    <Grid key={index} item xs={12} md={6} lg={4} sx={{verticalAlign: 'center'}}>
                        <MaxiNewsCard article={item}/>
                    </Grid>
                ))
                }
            </Grid>
        </Paper>
        :
        <></>
}
