import {Grid, Link, Typography} from "@mui/material";
import React from "react";

export function Copyright() {
    return (
        <Grid container component={"span"} sx={{p: 2, bgcolor: 'primary.main', display:{xs:'block', sm:'flex'}}} justifyContent={"space-between"} alignItems={"center"}>
            <Grid item>
                <Typography variant="body2" color="lightgrey" align="left" >
                    {'Szerzői Jog © '}
                    {new Date().getFullYear()} BZSH Scheiber Iskola | Budapest
                </Typography>
            </Grid>
            <Grid item>
                    <Typography variant="body2" color="lightgrey" align="right" >
                        {'Webmester: '}
                        <Link color="inherit" href="https://www.gecsevar.hu">
                            AstrA
                        </Link>
                    </Typography>
            </Grid>
        </Grid>
    )
}