import {HeaderBar} from "../components/HeaderBar";
import {Box, CircularProgress, CssBaseline, Grid, PaletteMode, Paper, Stack, Theme, ThemeProvider, Typography} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {Container} from "../components/Container";
import SEO from "../components/SEO";

import {GecsevarHuConfig} from "../config/gecsevar.hu/GecsevarHuConfig";
import {MadregotConfig} from "../config/madregot.hu/MadregotConfig";
import {HTML_HEAD_META, IGecsevarConfig} from "../config/IGecsevarConfig";
import {gecsevarHuTheme} from "../config/gecsevar.hu/GecsevarHuTheme";
import {madregotTheme} from "../config/madregot.hu/MadregotTheme";
import {scheiberTheme} from "../config/scheiber.hu/ScheiberTheme";
import {ScheiberConfig} from "../config/scheiber.hu/ScheiberConfig";

export let gecsevarConfig: IGecsevarConfig
export let gecsevarTheme: Theme

if (process.env.REACT_APP_SITE == "gecsevar") {
    gecsevarTheme = gecsevarHuTheme()
    gecsevarConfig = new GecsevarHuConfig()
} else if (process.env.REACT_APP_SITE == "madregot") {
    gecsevarTheme = madregotTheme()
    gecsevarConfig = new MadregotConfig()
} else if (process.env.REACT_APP_SITE == "scheiber") {
    gecsevarTheme = scheiberTheme()
    gecsevarConfig = new ScheiberConfig()
}

const ColorModeContext = React.createContext({
    toggleColorMode: () => {
    }
});

export function MainPage() {

    const [mode, setMode] = useState<PaletteMode>('dark')
    const [gecsevarConfigInit, setGecsevarConfigInit] = useState(false)

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode: PaletteMode) =>
                    prevMode === 'light' ? 'dark' : 'light',
                )
            },
        }),
        [],
    )

    useEffect(() => {
        gecsevarConfig.initialize(() => {
            // Site config must be loaded to load the menu and site structure!
            setGecsevarConfigInit(true)
        })

    }, [])

    if (!gecsevarConfigInit) {
        return (
            <Box sx={{height: '100vh', width: '100%'}}>
                <Paper elevation={4} sx={{
                    p: 0,
                    m: 0,
                }} component={Stack} direction="column" justifyContent="center">
                    <Box sx={{display: 'flex', justifyContent: 'center', p: 4, color: 'black'}}>
                        <CircularProgress thickness={3} size={50}
                                          sx={{
                                              '.MuiCircularProgress-colorPrimary': {
                                                  left: '43%',
                                                  position: 'absolute',
                                                  top: '44vh',
                                                  color: 'primary.main'
                                              }
                                          }}
                        />
                        <Typography variant={"h4"} fontWeight={"normal"} marginLeft={2}>Loading site config...</Typography>
                    </Box>
                </Paper>
            </Box>
        )
    } else {
        return (
            <ColorModeContext.Provider value={colorMode}>
                <SEO
                    title={gecsevarConfig.getHtmlHead(HTML_HEAD_META.META_TITLE)}
                    description={gecsevarConfig.getHtmlHead(HTML_HEAD_META.META_DESCRIPTION)}
                    name={gecsevarConfig.getHtmlHead(HTML_HEAD_META.META_NAME)}
                    type="article" />
                <ThemeProvider theme={gecsevarTheme}>
                    <CssBaseline/>
                    <HeaderBar/>
                    <Container/>
                    <Box sx={{
                        width: '100%',
                        color: 'white',
                        backgroundColor: 'primary.main',
                    }}>
                        {gecsevarConfig.getFooterContent()}
                    </Box>
                    <Grid container component={"span"} sx={{p: 0, background: 'primary.main', display: {xs: 'block', sm: 'flex'}}}
                          justifyContent={"space-between"} alignItems={"center"}>
                        {gecsevarConfig.getCopyrightContent()}
                    </Grid>
                </ThemeProvider>
            </ColorModeContext.Provider>
        )
    }
}