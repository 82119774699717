import {AxiosResponse} from "axios";
import {baseUrlAdmin, HttpService} from "../../gecsevar_framework/services/HttpService";

class SendMessageService extends HttpService {

    constructor() {
        super()
    }

    get(callback: (resp: AxiosResponse) => void) {
        this.httpClient.get(baseUrlAdmin + "menu.php", {
            params: {}
        })
            .then((resp: AxiosResponse) => {
                console.log(resp.data)
                callback(resp)
            })
            .catch(function (error: any) {
                // handle error
                console.log('hiba' + error)
            })
    }

    post(content: string, callback: (status: number, resp: AxiosResponse | string | null) => void) {
        this.httpClient.post(baseUrlAdmin + "send_message.php", content, {
            params: {}
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch(function (error: any) {
                // handle error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    callback(error.config.status, error.response.data["result_text"])
                    return
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                callback(error.config.status, "Ismeretlen hiba")
            })
    }
}

const sendMessageService = new SendMessageService()
export default sendMessageService